import { Box, FormControl, FormLabel, Theme, Typography, useMediaQuery } from "@mui/material";
import CabinetPage from "../../components/Common/CabinetPage";
import {
  GridEventListener, GridFilterModel, GridRowModel, GridSortModel, getGridDateOperators, getGridStringOperators
} from "@mui/x-data-grid-pro";
import { useCallback, useMemo, useState } from "react";
import { Leader, LeaderRelationshipDenorm } from "../../store";
import { Contact, ContactCategory, ContactType } from "../../store/cabinetApi/generated/crm";
import { DateTime } from "luxon";
import CRMTable, { CRMGridColDef } from '../../components/CRM/CRMTable';
import { CabExecPicker } from "@CabComponents/CabExecPicker";
import CabAvatar from "@CabComponents/CabAvatar";
import { getLeaderIconSrc } from "../../utils/leaderUtils";
import NewContactModal from "../CRMContacts/NewContactModal";
import { CabButton } from "@CabComponents/CabButton";
import { isLargeFormatDevice } from "../../utils/screenSizeUtils";
import SearchInputForm, { SearchForm } from "../../components/CRM/SearchInputForm";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoFlashOff } from "react-icons/io5";
import colors from "../../colors";
import { CabModal } from "@CabComponents/CabModal";


const CRMRelationships = ({
  relationships, page, pageSize, rowCount, onPageChange, onNavigateToContact, onCreateContact, filter, 
  onSetFilter, sort, onSetSort, loadingRelationships, onLeaderSelected, selectedLeaderId, leaderOptions,
  contactCategories, contactTypes, isCreatingContact, onSearch, onUpdateRelationship
}: {
  relationships: LeaderRelationshipDenorm[];
  contactCategories: ContactCategory[];
  contactTypes: ContactType[];
  isCreatingContact: boolean;
  page: number;
  pageSize: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onNavigateToContact: (id: LeaderRelationshipDenorm['id']) => void;
  onCreateContact: (contact: Omit<Contact, 'id'>, relationshipLeaders?: Leader['id'][]) => Promise<void>;
  filter?: GridFilterModel;
  onSetFilter: (filter: GridFilterModel | undefined) => void;
  sort?: GridSortModel;
  onSetSort: (sort: GridSortModel | undefined) => void;
  loadingRelationships: boolean;
  selectedLeaderId?: Leader['id'];
  onLeaderSelected: (leaderId: Leader['id']) => void;
  leaderOptions: Leader[];
  onSearch: (search: string) => void;
  onUpdateRelationship: (
    contact: Partial<LeaderRelationshipDenorm> & { id: LeaderRelationshipDenorm['id'] }
  ) => Promise<LeaderRelationshipDenorm | undefined>
}) => {
  const [openNewContactModal, setOpenNewContactModal] = useState(false);
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const cabExecOptions = useMemo(() => leaderOptions.map(leader => {
    return {
      value: leader.id,
      label: `${leader.first_name} ${leader.last_name}`,
      icon: <CabAvatar
        src={getLeaderIconSrc(leader)}
        color={leader.color}
        name={`${leader.first_name}
        ${leader.last_name}`}
        size="small"
      />
    };
  }), [leaderOptions]);

  const handleSearch = ({ search }: SearchForm) => {
    onSearch(search);
  };

  return (
    <CabinetPage
      pageName="Relationships"
      headerContent={
        <Box width='100%' display='flex' justifyContent='space-between'>
          <Typography variant="h1">
            Relationships
          </Typography>
          {!isSmDown && (
            <SearchInputForm
              onSearch={handleSearch}
              sx={{ flex: 1, maxWidth: 500, paddingLeft: 4, paddingRight: 4 }}
            />
          )}
          {isLargeFormatDevice() && (
            <CabButton
              onClick={() => setOpenNewContactModal(true)}
            >
              New Contact
            </CabButton>
          )}
        </Box>
      }
    >
      <>
        <Box padding={2} display="flex" flexDirection="column" flex={1}>
          <Box display="flex" justifyContent="space-between">
            <FormControl>
              <FormLabel sx={{ fontWeight: 'bold' }}>Show Relationships with</FormLabel>
              <CabExecPicker
                value={selectedLeaderId || -1}
                options={cabExecOptions}
                onChange={onLeaderSelected}
                placeholder="Select a Teammate"
                sx={{ width: 300, marginTop: '0px !important' }}
              />
            </FormControl>
            {isSmDown && (
              <SearchInputForm onSearch={handleSearch} />
            )}
          </Box>

          <Box position="relative" height="100%">
            <Box position="absolute" top={0} bottom={0} left={0} right={0}>
              <RelationshipTable
                relationships={relationships}
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={onPageChange}
                onNavigateToRelationship={onNavigateToContact}
                filter={filter}
                onSetFilter={onSetFilter}
                sort={sort}
                onSetSort={onSetSort}
                defaultHiddenFields={['title']}
                loading={loadingRelationships}
                onUpdateRelationship={onUpdateRelationship}
              />
            </Box>
          </Box>
        </Box>

        <NewContactModal
          open={openNewContactModal}
          onClose={() => setOpenNewContactModal(false)}
          contactCategories={contactCategories}
          contactTypes={contactTypes}
          onCreateContact={onCreateContact}
          isCreatingContact={isCreatingContact}
          leaderOptions={leaderOptions}
        />
      </>
    </CabinetPage>
  );
};

const relationshipStrengthNumToStr = (value: number) => {
  if (value >= 7) return 'strong';
  if (value >= 3) return 'good';
  if (value >= 0) return 'weak';
  return 'auto';
};

const relationshipColumns: CRMGridColDef<LeaderRelationshipDenorm>[] = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    width: 200,
    display: "flex",
    filterOperators: getGridStringOperators().filter(op => !['isAnyOf', 'isEmpty', 'isNotEmpty'].includes(op.value)),
    valueGetter: (value, row, column, apiRef) => row.contact?.name || '',
  },
  {
    field: 'title',
    headerName: 'Title',
    type: 'string',
    width: 200,
    display: "flex",
    valueGetter: (value, row, column, apiRef) => row.contact?.title || '',
  },
  {
    field: 'primary_email',
    headerName: 'Email',
    type: 'string',
    width: 200,
    display: "flex",
    valueGetter: (value, row, column, apiRef) => row.contact?.primary_email || '',
  },
  {
    field: 'prev_meeting_data',
    headerName: 'Last Meeting',
    type: 'dateTime',
    width: 200,
    display: "flex",
    valueGetter: (value, row, column, apiRef) => (
      row.prev_meeting_data?.['v1_15']
        ? DateTime.fromISO(row.prev_meeting_data['v1_15'].start_date).toJSDate()
        : undefined
    ),
    valueFormatter: (val?: Date) => val && DateTime.fromJSDate(val).toLocaleString({
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: '2-digit',
      timeZoneName: 'short',
    }),
    renderCell: (params) => {
      const tooltip = params.row.prev_meeting_data?.['v1_15']
        ? `${params.row.prev_meeting_data['v1_15'].title}\n${params.formattedValue}`
        : '';
      return (
        <CabTooltip title={tooltip} wrapWithSpan>
          {params.formattedValue}
        </CabTooltip>
      );
    }
  },
  {
    field: 'next_meeting_data',
    headerName: 'Next Meeting',
    type: 'dateTime',
    width: 200,
    display: "flex",
    valueGetter: (value, row, column, apiRef) => (
      row.next_meeting_data?.['v1_15']
        ? DateTime.fromISO(row.next_meeting_data['v1_15'].start_date).toJSDate()
        : undefined
    ),
    valueFormatter: (val?: Date) => val && DateTime.fromJSDate(val).toLocaleString({
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: '2-digit',
      timeZoneName: 'short',
    }),
    renderCell: (params) => {
      const tooltip = params.row.next_meeting_data?.['v1_15']
        ? `${params.row.next_meeting_data['v1_15'].title}\n${params.formattedValue}`
        : '';
      return (
        <CabTooltip title={tooltip} wrapWithSpan>
          {params.formattedValue}
        </CabTooltip>
      );
    }
  },
  {
    field: 'relationship_strength',
    headerName: 'Relationship Strength',
    width: 160,
    display: "flex",
    type: 'singleSelect',
    chipSelect: true,
    editable: true,
    valueOptions: [
      { value: 'auto', label: 'Auto', color: colors.black300 },
      { value: 'weak', label: 'Weak', color: '#FCF55F', },
      { value: 'good', label: 'Good', color: colors.blueLight },
      { value: 'strong', label: 'Strong', color: colors.greenPrimary },
    ],
    // filterOperators: getGridSingleSelectOperators().filter(op => op.value !== 'not'),
    valueGetter: (value, row, column, apiRef) => {
      return relationshipStrengthNumToStr(Number(row.relationship_strength));
    },
    valueSetter: (value, row) => {
      return {
        ...row,
        relationship_strength_override: value === 'auto' ? null : value,
      };
    },
  },
  {
    field: 'relationship_strength_override',
    headerName: '',
    width: 30,
    display: "flex",
    type: 'boolean',
    valueGetter: (value, row, column, apiRef) => value != null,
    renderCell: ({ value }) => (value && <CabIcon Icon={IoFlashOff} />),
    filterable: false,
    sortable: false,
  },
  {
    field: 'relationship_strength_auto',
    headerName: 'Auto RS',
    width: 100,
    display: "flex",
    type: 'singleSelect',
    filterable: false,
    sortable: false,
  },
  {
    field: 'contact_created_at',
    headerName: 'Created On',
    type: 'dateTime',
    width: 200,
    display: "flex",
    filterOperators: getGridDateOperators(false).filter(op => ['onOrAfter', 'onOrBefore'].includes(op.value)),
    valueGetter: (value, row, column, apiRef) => (
      row.contact?.created_at ? DateTime.fromISO(row.contact.created_at).toJSDate() : undefined
    ),
    valueFormatter: (value, row, column, apiRef) => (
      value ? DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_MED) : ''
    ),
  },
];

export const RelationshipTable = ({
  relationships, onNavigateToRelationship, page, pageSize, rowCount, onPageChange, filter, onSetFilter,
  sort, onSetSort, loading, defaultHiddenFields, onUpdateRelationship
}: {
  relationships: LeaderRelationshipDenorm[];
  page: number;
  pageSize: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onNavigateToRelationship: (id: LeaderRelationshipDenorm['id']) => void;
  filter?: GridFilterModel;
  onSetFilter: (filter: GridFilterModel | undefined) => void;
  sort?: GridSortModel;
  onSetSort: (filter: GridSortModel | undefined) => void;
  defaultHiddenFields?: string[];
  loading: boolean;
  onUpdateRelationship: (
    contact: Partial<LeaderRelationshipDenorm> & { id: LeaderRelationshipDenorm['id'] }
  ) => Promise<LeaderRelationshipDenorm | undefined>
}) => {
  const [promiseArguments, setPromiseArguments] = useState<{
    resolve: (value: LeaderRelationshipDenorm) => void,
    reject: (value: LeaderRelationshipDenorm) => void,
    originalRow: GridRowModel<LeaderRelationshipDenorm>;
    updatedRow: GridRowModel<LeaderRelationshipDenorm>;
  } | null>(null);

  const handleConfirmCancel = () => {
    if (!promiseArguments) return;
    const { originalRow, resolve } = promiseArguments;
    resolve(originalRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleConfirm = async () => {
    if (!promiseArguments) return;
    const { updatedRow, originalRow, reject, resolve } = promiseArguments;

    try {
      await handleRowUpdate(updatedRow, originalRow);
      resolve(updatedRow);
      setPromiseArguments(null);
    } catch (error) {
      reject(originalRow);
      setPromiseArguments(null);
    }
  };

  const handleCellClick = useCallback<GridEventListener<'cellClick'>>((params, event, details) => {
    if (params.field === 'name' && params.row.contact) {
      onNavigateToRelationship(params.row.contact.id);
    }
  }, [onNavigateToRelationship]);

  const handleRowUpdate = useCallback(async (
    updatedRow: LeaderRelationshipDenorm, originalRow: LeaderRelationshipDenorm,
  ) => {
    const res = await onUpdateRelationship(updatedRow);
    return res || originalRow;
  }, [onUpdateRelationship]);

  return (
    <>
      <CRMTable
        columns={relationshipColumns}
        rows={relationships}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        onPageChange={onPageChange}
        onCellClick={handleCellClick}
        filter={filter}
        onSetFilter={onSetFilter}
        sort={sort}
        onSetSort={onSetSort}
        loading={loading}
        defaultHiddenFields={defaultHiddenFields}
        onRowUpdate={handleRowUpdate}
        dataGridProps={{
          processRowUpdate: async (updatedRow, originalRow) => {
            // if useroverride is changed to something other than auto, trigger modal
            if (updatedRow.relationship_strength_override
              !== originalRow.relationship_strength_override
              // and if original row was not already being overridden
              && originalRow.relationship_strength_override == null
              && updatedRow.relationship_strength_override != null
            ) {
              return new Promise((resolve, reject) => {
                // Save the arguments to resolve or reject the promise from modal
                setPromiseArguments({ resolve, reject, updatedRow, originalRow });
              });
            }

            handleRowUpdate(updatedRow, originalRow);
            return updatedRow;
          }
        }}
      />
      {promiseArguments && (
        <CabModal
          open={true}
          onClose={handleConfirmCancel}
          isAlert
          noFullScreen={true}
          text= "Relationship score will no longer be calculated automatically. Are you sure you want to override it?"
          actionButtons={
            <>
              <CabButton buttonType='secondary' onClick={handleConfirmCancel}>
                Cancel
              </CabButton>
              <CabButton onClick={handleConfirm} color="error">
                Yes
              </CabButton>
            </>
          }
        />
      )}
    </>
  );
};


export default CRMRelationships;
